import styled from "styled-components"

const ImageGrid = styled.div`
  width: 100%;
  display: grid;
  margin-top: 1em;
  grid-template-columns: repeat(4, 25%);
  justify-items: center;
  .image-child {
    width: 250px;
    height: 250px;
    object-fit: cover;
    margin-bottom: 1em;
  }
  @media only screen and (min-width: 600px) and (max-width: 992px) {
    .image-child {
      width: 200px;
      height: 200px;
    }
  }
  @media only screen and (max-width: 730px) {
    grid-template-columns: repeat(3, 33%);
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 50%);
    .image-child {
      height: 50vw;
      width: 50vw;
    }
  }
`
export default ImageGrid
