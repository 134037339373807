import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageGrid from "../components/image-grid"

const PortfolioWrapper = styled.div`
  width: rhythm(32);
  margin: auto;
  text-align: center;
`

const Portfolio = () => {
  const imageQuery = useStaticQuery(graphql`
    {
      gridImages: allFile(
        filter: { relativeDirectory: { eq: "portfolio/grid" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      bigImages: allFile(filter: { relativeDirectory: { eq: "portfolio" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const bigImages = imageQuery.bigImages.edges
  const gridImages = imageQuery.gridImages.edges

  return (
    <Layout>
      <SEO title="Portfolio" />
      <PortfolioWrapper>
        <Img fluid={bigImages[0].node.childImageSharp.fluid}></Img>
        <ImageGrid>
          {gridImages.map(image => (
            <Img
              className="image-child"
              key={image.node.childImageSharp.fluid.src}
              fluid={image.node.childImageSharp.fluid}
            />
          ))}
        </ImageGrid>
        <Img
          fluid={bigImages[1].node.childImageSharp.fluid}
          style={{ margin: "1em 0" }}
        ></Img>
        <Img
          fluid={bigImages[2].node.childImageSharp.fluid}
          style={{ margin: "1em 0" }}
        ></Img>
        <Img
          fluid={bigImages[3].node.childImageSharp.fluid}
          style={{ margin: "1em 0" }}
        ></Img>
        <Img
          fluid={bigImages[4].node.childImageSharp.fluid}
          style={{ margin: "1em 0" }}
        ></Img>
      </PortfolioWrapper>
    </Layout>
  )
}

export default Portfolio
